<template>
    <div class="mt-5 px-xl-5">
        <b-row class="justify-content-center">
            <b-col lg="8" xl="6">
                <h6 class="page-title pb-2 text-uppercase">
                    {{ $t("send-resident-agent-invitation.title") }}
                </h6>
            </b-col>
        </b-row>
        <b-row class="mb-5 justify-content-center">
            <b-col lg="8" xl="6">
                <b-card class="shadow-sm border-0 rounded-0 px-md-4" bg-variant="light" no-header>
                    <b-card-text>
                        <b-row class="mt-2">
                            <b-col>
                                <b-form-group>
                                    <label class="form-title" label-for="name-input">Correo electr&oacute;nico</label>
                                    <b-form-input placeholder="xxx@xxx.com" id="name-input"
                                        v-model="invitationForm.email" size="sm" required class="rounded-0"
                                        autocomplete="off"></b-form-input>
                                </b-form-group>
                                <b-form-group class="mt-3">
                                    <label class="form-title" label-for="name-input">Tipo de usuario</label>
                                    <select class="
                                            form-select form-select-sm
                                            rounded-0
                                        " v-model="invitationForm.selectedType">
                                        <option v-for="(
                                                item, index
                                            ) in invitationForm.types" :key="index" :value="item.value">
                                            {{ item.text }}
                                        </option>
                                    </select>
                                </b-form-group>
                                <b-form-group v-if="showLawFirms" class="mt-3">
                                    <label class="form-title" label-for="name-input">
                                        Firma de Abogados / Persona Natural
                                    </label>
                                    <v-select v-model="lawFirmId" label="name" :options="lawFirms"
                                        :reduce="(lf) => lf.id" :clearable="false">
                                        <template #selected-option="option">
                                            {{ option.name }}
                                        </template>
                                        <template #option="option">
                                            {{ option.name }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <!-- <b-form-group v-if="showAdmin" class="mt-3">
                                    <b-form-checkbox
                                        v-model="invitationForm.isAdmin"
                                    >
                                        <span class="ms-2">
                                            Administrador
                                        </span>
                                    </b-form-checkbox>
                                </b-form-group> -->
                            </b-col>
                        </b-row>
                        <b-row class="my-4">
                            <b-col class="text-end">
                                <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
                                    class="d-inline-block">
                                    <b-button class="main-btn rounded px-4" :disabled="!canSend"
                                        @click="sendInvitation">Enviar</b-button>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "send-resident-agent-invitation",
    data() {
        return {
            sending: false,
            invitationForm: {
                email: "",
                types: [
                    { value: 0, text: "Abogado" },
                    { value: 1, text: "Asistente" },
                    { value: 2, text: "Interno" },
                    { value: 3, text: "Official de cumplimiento" },
                ],
                selectedType: null,
                isAdmin: false,
            },
            showAdmin: false,
            lawFirmId: null,
            lawFirms: [],
        };
    },
    async mounted() {
        if (!this.allowInvite) {
            this.$router.push({
                name: "resident-agent-list",
            });
        }

        if (this.showLawFirms) {
            await this.loadLawFirms();
        }

        if (this.isSuperintendence) {
            this.showAdmin = true;
        } else if (this.isResidentAgent) {
            let residentAgent = await this.getUserResidentAgent();
            // If is admin show the option
            this.showAdmin = residentAgent.isAdmin;
        }
    },
    computed: {
        ...mapGetters("application", [
            "isResidentAgent",
            "isSuperintendence",
            "allowSuperintendenceCreateRA",
            "allowResidentAgentCreateRoles",
        ]),
        allowInvite() {
            return (
                this.allowSuperintendenceCreateRA ||
                this.allowResidentAgentCreateRoles
            );
        },
        showLawFirms() {
            return this.isSuperintendence;
        },
        canSend() {
            const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            let validData =
                regex.test(this.invitationForm.email) &&
                this.invitationForm.selectedType !== null;
            if (this.showLawFirms) {
                return validData && this.lawFirmId !== null;
            }
            return validData;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async loadLawFirms() {
            try {
                var response = await RepositoryFactory.lawFirm.getAll({ search: "", status: 0 });
                this.lawFirms = response.data;
            } catch (error) {
                window.console.log(error);
            }
        },
        async getUserResidentAgent() {
            try {
                let response =
                    await RepositoryFactory.residentAgent.getByUser();
                return response.data;
            } catch (error) {
                window.console.log(error);
                return null;
            }
        },
        async sendInvitation() {
            this.sending = true;
            try {
                let payload = {
                    residentAgentInvitation: {
                        email: this.invitationForm.email,
                        residentAgentType: this.invitationForm.selectedType,
                        lawFirmId: this.lawFirmId,
                        isAdmin: this.invitationForm.isAdmin,
                    },
                };
                await RepositoryFactory.residentAgent.sendInvitation(payload);
                this.setSuccesInfo();
            } catch (error) {
                window.console.log(error);
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: "Invitación enviada",
                message:
                    "Se ha enviado la invitación de registro correctamente.",
                routeName: "resident-agent-list",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: "Error enviando la invitación",
                message:
                    "Se ha producido un error al intentar enviar la invitación.",
                subMessage: "Por favor vuelva a intentarlo nuevamente",
            });
        },
    },
};
</script>